import { OrgRoundParticipationDetail } from '../components/Pairing/Round';

export function OrganizationPairingRoundParticipationDetailRoute() {
  return <OrgRoundParticipationDetail />;
}

export const Component = OrganizationPairingRoundParticipationDetailRoute;

export function clientLoader() {
  return null;
}
