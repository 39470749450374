import { useParams } from '@remix-run/react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type PairingParticipation } from '../../../types';
import { assertExhaustive } from '../../../utils/common';
import { Loading } from '../../Loading';

function useStatusLabel(status: PairingParticipation['status']): string {
  switch (status) {
    case 'opted_in':
      return '✅ Opted In';
    case 'opted_out':
      return '🚫 Opted Out';
    case 'unknown':
      return '🤔 Requested by Others';
    default:
      assertExhaustive(status);
      return 'Unknown';
  }
}

function Participation(props: {
  participation: PairingParticipation;
}): JSX.Element {
  const { participation } = props;
  const statusLabel = useStatusLabel(participation.status);
  return (
    <tr className='h-10'>
      <td>
        {participation.organizer?.firstName} {participation.organizer?.lastName}
      </td>
      <td>{statusLabel}</td>
      <td>
        {participation.suggestedUsers?.map((u) => u.fullName).join(', ') ??
          'N/A'}
      </td>
    </tr>
  );
}

export function OrgRoundParticipationDetail(): JSX.Element {
  const { roundId } = useParams<'roundId'>();
  const {
    data: items,
    isValidating,
    error,
  } = useSWR(`/pairing-rounds/${roundId}/participation-detail`, async () => {
    if (!roundId) return [];
    return (await apiService.pairing.getOrgRoundParticipationDetail(roundId))
      .data.items;
  });

  if (isValidating) return <Loading />;

  if (error) return <div className='text-lp-red-002'>{error}</div>;

  if (!items) return <div>No data found</div>;

  return (
    <div>
      <div className='font-medium text-2xl truncate mb-10'>
        Pairing Opt In / Out Status
      </div>
      <table className='w-full text-left'>
        <thead>
          <tr className='h-10'>
            <th>User</th>
            <th>Status</th>
            <th>Requested Match</th>
          </tr>
        </thead>
        <tbody className='text-sm'>
          {items.map((p) => (
            <Participation key={p.id} participation={p} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
